* {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

:root {
    --body-bg: #111;
    --text-color: #fff;
}

body {
    background: var(--body-bg);
    color: var(--text-color);
}

.menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--body-bg);
}

.skills {
    background: var(--body-bg);
    color: var(--text-color);
}

.menu ul li {
    font-size: 4rem;
    font-weight: bolder;
    width: auto;
    margin: 3vh 0;
}

.menu ul li:hover {
    font-size: 4rem;
    width: auto;
    margin: 3vh 0;
}

.hamburger-react {
    z-index: 999;
}

.image-resize {
    height: 70vh;
    width: 65vh;
}

.image-resize img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* background: red; */
}

.tag {
    font-size: 3rem;
    font-weight: bold;
}

.contact {
    font-size: 12vw;
    font-family: 'Abril Fatface', serif;
    display: flex;
    justify-content: center;
}

.circle {
    position: fixed;
    bottom: -6rem;
    left: -5rem;
    
}

.circular-container {
    position: relative;
}

.circularText {
    animation: rotate 15s linear infinite;
    fill: var(--text-color);
    transform-origin: 150px 200px;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.inside {
    background: var(--text-color);
    color: var(--body-bg);
    border: 2px solid var(--text-color);
    height: 5rem;
    width: 5rem;
    position: absolute;
    bottom: 20%;
    left: 50.5%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.8s ease;
}

.inside:hover {
    background: var(--body-bg);
    color: var(--text-color);
}

.form-control {
    border: none;
    outline: none;
    border-bottom: 1px solid rgb(189, 189, 189);
}

.form-control::placeholder {
    color: rgb(185, 185, 185);
    /* font-weight: bold; */
}

.form-control:focus {
    border-bottom: 3px solid #ffffff;
    box-shadow: none;
}

.pulse-element {
    animation: pulse 1s infinite;
}

@keyframes pulse {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.imageAbout {
    height: 65vh;
    width: 45vh;
}

.imageAbout img {
    height: 60vh;
    width: 55vh;

}

.boxShad {
    position: relative;
    border: 2px solid var(--text-color);
    background: var(--body-bg);
    padding: 8px;
}

.boxCon {
    position: absolute;
    top: 1%;
    right: -4%;
    z-index: -10;
    width: 102%;
    height: 102%;
    background: var(--text-color);
}

.hover-effect {
    display: inline-block;
    transition: transform 0.2s ease;
}

.hover-effect:hover {
    transform: translateX(10px);
}

.hover-slide {
    display: inline-flex;
    align-items: center;
    transition: transform 0.3s ease;
}

.hover-slide {
    transform: translateX(-25%);
    transition: transform 0.5s ease;
}

.hover-slide:hover {
    transform: translateX(5px);
    /* Adjust the value to control the amount of slide */
}

.hover-slide:hover::before {
    transform: translateX(0);
}

